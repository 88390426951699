<template>
  <div>
    <a-modal
      title="添加/编辑盘源"
      width="800px"
      :visible="visible"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="handleOk"
      :footer="null"
      @cancel="handleCancel"
    >
      <a-form :form="form" class="form">
        <a-row :gutter="24" style="margin-bottom:10px;">
          <a-col :span="24">
            <a-form-item labelAlign="left" label="盘源图片" :required="true" :labelCol="{
              xs: { span: 24 },
              sm: { span: 3 }
            }" :wrapperCol="{
              xs: { span: 24 },
              sm: { span: 21 }
            }">
              <div v-for="(item, index) in imageList" :key="index" style="width:90px;float:left;margin-right:10px;border: 1px solid #ccc;text-align: center;">
                <img style="width:80px;height:40px;border-radius:5px;" :src="item.image"/>
                <span style="color:red;" v-if="item.image === image">封面</span>
                <span v-else style="cursor: pointer;color:gray;" @click="updateImage(item.image)">设为封面</span>
                <a-icon @click="delImage(index)" type="delete" />
              </div>
              <a-upload
                name="file"
                :action="imgurl"
                :showUploadList="false"
                :headers="{authorization: 'authorization-text'}"
                @change="changeImage"
              >
                <a-button icon="upload" style="width:100%;">
                  图片上传</a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="房号">
              <a-input placeholder="请输入房号" v-decorator="['roomNum', {rules: [{required: true, message: '请输入房号'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="盘源类型">
              <a-select
                v-decorator="[
                  'type',
                  { rules: [{ required: true, message: '请选择盘源类型!' }] },
                ]"
                placeholder="请选择盘源类型"
              >
                <a-select-option :value="1">
                  新房
                </a-select-option>
                <a-select-option :value="2">
                  二手房
                </a-select-option>
                <a-select-option :value="3">
                  租房
                </a-select-option>
                <a-select-option :value="4">
                  商铺
                </a-select-option>
                <a-select-option :value="5">
                  写字楼
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="业主姓名">
              <a-input placeholder="请输入业主姓名" v-decorator="['owner', {rules: [{required: true, message: '请输入业主姓名'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="业主电话">
              <a-input placeholder="请输入业主电话" v-decorator="['ownerMobile', {rules: [{required: true, message: '请输入业主电话'}]}]"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="房产金额">
              <a-input style="width:100%;" placeholder="请输入房产金额" v-decorator="['money', {rules: [{required: true, message: '请输入房产金额'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="栋座">
              <a-input placeholder="请输入栋座" v-decorator="['building', {rules: [{required: true, message: '请输入栋座'}]}]"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="楼盘">
              <a-input placeholder="请输入楼盘" v-decorator="['floorDisc', {rules: [{required: true, message: '请输入楼盘'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="楼层">
              <a-input placeholder="请输入楼层" v-decorator="['storey', {rules: [{required: true, message: '请输入楼层'}]}]"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="房产面积">
              <a-input-number style="width:100%;" placeholder="请输入房产面积" v-decorator="['area', {rules: [{required: true, message: '请输入房产面积'}]}]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item labelAlign="left" :labelCol="labelCol" :wrapperCol="wrapperCol" label="售卖方式">
              <a-select
                v-decorator="[
                  'mode',
                  { rules: [{ required: true, message: '盘源售卖方式方式!' }] },
                ]"
                placeholder="盘源售卖方式方式"
              >
                <a-select-option :value="1">
                  售卖
                </a-select-option>
                <a-select-option :value="2">
                  整租
                </a-select-option>
                <a-select-option :value="3">
                  办公
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item labelAlign="left" :required="true" :labelCol="labelCol" :wrapperCol="wrapperCol" label="委托协议">
              <a-upload
                name="file"
                :action="imgurl"
                :fileList="agreement"
                :headers="{authorization: 'authorization-text'}"
                @change="changeAnnex"
              >
                <a-button icon="upload" style="width:100%;">
                  上传委托协议</a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div style="text-align: center;">
        <a-button @click="handleOk" type="primary">
          提交信息
        </a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import config from '@/http/config'
import moment from 'moment'
import store from '@/store'
import matchFun from '@/utils/matchFun'
import { mapState } from 'vuex'
export default {
  components: {
  },
  data () {
    return {
      visible: false,
      confirmLoading: false,
      matchFun: matchFun,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      item: null,
      imgurl: config.baseImgURL,
      imageList: [],
      agreement: [],
      image: '',
      form: this.$form.createForm(this)
    }
  },
  computed: {
    ...mapState({
    })
  },
  methods: {
    moment,
    showModal (item) {
      this.item = item
      this.image = ''
      this.agreement = []
      this.imageList = []
      if (item !== null) {
        setTimeout(() => {
          this.form.setFieldsValue({
            money: item.money,
            area: item.area,
            owner: item.owner,
            ownerMobile: item.ownerMobile,
            type: item.type,
            mode: item.mode,
            floorDisc: item.floorDisc,
            building: item.building,
            storey: item.storey,
            roomNum: item.roomNum
          })
          this.image = item.image
          this.agreement.push(this.matchFun.randomNumFile('委托协议.doc', item.agreement))
          this.findList(this.item.id)
        })
      }
      this.visible = true
    },
    findList (houseId) {
        this.$api.diskSourceImage.list(houseId).then(res => {
            if (res.status === 0) {
                res.data.forEach(element => {
                    this.imageList.push({
                      image: element.image
                    })
                });
                
            } else {
                this.$message.warn(res.msg)
            }
        }).catch(err => {
            console.log(err)
        })
    },
    delImage (index) {
      this.imageList.splice(index, 1)
    },
    updateImage (image) {
      this.image = image
    },
    mapData (e) {
      this.position = e.position
    },
    changeAnnex (info) {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.data.url
        }
        return file
      })
      this.agreement = fileList
      if (info.file.status === 'done') {
        this.$message.success(`委托协议上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`委托协议上传失败`)
      }
    },
    changeImage (info) {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.data.url
        }
        return file
      })
      if (info.file.status === 'done') {
        this.imageList.push({
          image: fileList[0].response.data.qiniuUrl
        })
        this.$message.success(`图片上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`图片上传失败`)
      }
    },
    handleOk (e) {
      if (this.image === '') {
        this.$message.warn('请选择封面！')
        return
      }
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          var upData = {...values}
          upData.id = this.item !== null ? this.item.id : null
          upData.image = this.image
          let image = {
            list: this.imageList
          }
          upData.imageList= image
          if (this.agreement.length > 0) {
            if (this.agreement[0].response.data.qiniuUrl) {
              upData.agreement = this.agreement[0].response.data.qiniuUrl
            } else {
              upData.agreement = this.agreement[0].url
            }
          }
          
          this.$api.diskSource.save(upData).then(res => {
            if (res.status === 0) {
              this.$message.success('保存成功')
              this.handleCancel()
            } else {
              this.$message.warn(res.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      })
    },
    handleCancel (e) {
      this.visible = false
      this.$emit('ok')
    }
  }
}
</script>
<style scoped>

.search-box {
  width: 80%;
  box-shadow: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 32px;
}

</style>
