<template>
  <a-modal v-model="visible" title="跟进情况" :footer="null">
    <a-input placeholder="请输入信息" style="width:300px;margin-right:10px;" v-model="content"/>
      <a-button type="primary" @click="submit">
        提交信息
      </a-button>
    <a-timeline style="margin-top:20px;">
      <a-timeline-item v-for="(item, index) in list" :key="index">{{ item.content }}&ensp;&ensp;{{ matchFun.formatNumDate(item.createTime) }}</a-timeline-item>
    </a-timeline>
  </a-modal>
</template>
<script>
import matchFun from '@/utils/matchFun'
export default {
  data() {
    return {
      visible: false,
      content: '',
      targetId: null,
      type: null,
      list: [],
      matchFun
    };
  },
  methods: {
    showModal(targetId, type) {
      this.targetId = targetId
      this.type = type
      this.visible = true
      this.findList()
    },
    findList () {
      this.$api.contactInfo.list(this.targetId, this.type).then(res => {
        if (res.status === 0) {
          this.list = res.data
        } else {
          this.$message.warn(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    submit () {
      if (this.content === '') {
        this.$message.warn('请输入信息！')
        return
      }
      let valData = {
        targetId: this.targetId,
        type: this.type,
        content: this.content
      }
      this.$api.contactInfo.save(valData).then(res => {
        this.content = ''
        if (res.status === 0) {
          this.$message.success('保存成功')
          this.findList()
          this.$emit('ok')
        } else {
          this.$message.warn(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
  },
};
</script>
<style scoped>

</style>